<template>
  <div class="queryBrand">
    <div style="background: linear-gradient(to right, #1677ff, #2f99ff)">
      <nav-bar
        title="查原厂件"
        leftColor="#fff"
        style="
          background: linear-gradient(to right, #1677ff, #2f99ff);
          color: #fff;
        "
      ></nav-bar>
      <!-- <div class="search" style="margin-top: -4px;">
        <van-search
                v-model="brandName"
                placeholder="请输入品牌名称"
                shape
                :clearable="false"
        >
          <template #left-icon>
            <div style="visibility: hidden; width: 0"></div>
          </template>
          <template #right-icon>
            <div class="icon" @click="search"><van-icon name="search" /></div>
          </template>
        </van-search>
      </div> -->
    </div>

    <van-index-bar :index-list="indexList" :sticky-offset-top="46">
      <span v-for="(item, index) in brandList" :key="index">
        <van-index-anchor :index="indexList[index]" style="z-index: 2">{{
          indexList[index]
        }}</van-index-anchor>
        <van-grid center>
          <van-grid-item
            v-for="(key, index) in item[indexList[index]]"
            :key="index"
            @click="openBrandSheet(key)"
          >
            <div class="flex" style="flex-direction: column">
              <van-image
                :src="key.url"
                style="width: 35px; height: 35px"
              ></van-image>
              <div style="text-align: center;font-size:12px;margin-top:5px">{{ key.brandName }}</div>
            </div>
          </van-grid-item>
        </van-grid>
      </span>
    </van-index-bar>
    <van-action-sheet v-model="show" description="请选择品牌">
      <van-cell v-for="(item,index) in actions" :key="index" @click="toBrandList(item)" style="text-align:center">
        {{item.brandName}}
      </van-cell>
      </van-action-sheet>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import { getCarBrandList, getCarBrandListByLogoCode } from "@/api";
import {getParams} from '@/utils/util';
import {
  Search,
  Icon,
  IndexBar,
  IndexAnchor,
  Cell,
  Grid,
  GridItem,
  Image,
  Lazyload,
  Toast,
  ActionSheet
} from "vant";
export default {
  name: "queryOrigin",
  components: {
    NavBar,
    [Search.name]: Search,
    [Icon.name]: Icon,
    [IndexBar.name]: IndexBar,
    [IndexAnchor.name]: IndexAnchor,
    [Cell.name]: Cell,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Image.name]: Image,
    [Lazyload.name]: Lazyload,
    [ActionSheet.name]: ActionSheet,
  },
  data() {
    return {
      brandList: [],
      indexList: [],
      userId: '',
      show: false,
      actions: []
    };
  },
  methods: {
    onSelect(item) {
      console.log(item)

    },
    openBrandSheet(key) {
      console.log(key)
      var { brandId} = key;
      getCarBrandListByLogoCode({userId: this.userId, logoCode: brandId})
      .then(res=>{
        this.actions = res.data.data.list || []
        this.show = true;

      })

    },
    //品牌搜索
    getBrandList() {
      console.log(this.$route.params.type)
      Toast.loading({
        message: '加载中',
        duration: 0,
        forbidClick: true
      })
      getCarBrandList({ userId: this.userId, type: this.$route.params.type }).then((res) => {
        var { firstcharList, arrayAllBrand } = res.data.data;
        this.brandList = arrayAllBrand;
        this.indexList = firstcharList.map((val) => {
          return val.firstchar;
        });
        Toast.clear()
      });
    },
    //跳转配件列表
    toBrandList(item) {
      console.log(item)
      this.$router.push({ name: "brandList", params: {brandId: item.brandId, brandName: item.brandName} });
    },
  },
  mounted() {
    this.getBrandList();
  },
  beforeRouteEnter (to, from, next) {
    if(getParams().userId){
      //品牌商单独跳转情况
      var {userId,partyId,fromUrl,source} = getParams();
      next(vm=>{
        vm.userId = userId;
        sessionStorage.setItem('userId',userId)
        sessionStorage.setItem('partyId',partyId)
        sessionStorage.setItem('fromUrl',window.decodeURIComponent(fromUrl))
        sessionStorage.setItem('source',source)
      })
    }else{
      next(vm=>{
        vm.userId = sessionStorage.getItem('userId')
      })
    }
  }
};
</script>

<style lang="less" scoped>
.queryBrand {
  padding-top: 50px;
  /deep/.van-index-anchor--sticky {
    z-index: 2222;
  }
  /deep/.van-nav-bar {
    z-index: 9999 !important;
  }
  /deep/.van-search {
    background: linear-gradient(to right, #1677ff, #2f99ff);
  }
  /deep/.van-nav-bar__left i {
    color: #fff !important;
  }
  /deep/.van-ellipsis span {
    font-size: 18px;
    font-weight: 500;
    color: #fff !important;
  }
  .search {
    .flex {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .line {
        height: 20px;
        margin: 0 10px;
        width: 1px;
        background: #ccc;
      }
      .icon {
        width: 30px;
        height: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>